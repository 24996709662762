import { ActivatedRoute, Router } from '@angular/router';
import { LowerCasePipe, isPlatformBrowser } from '@angular/common';
import JsonProcessStatus from '@utils/json/process-status.json';
import {
  Inject,
  NgModule,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { StageFormValidateService } from '@apiShared/process-status/stage-form-validate/stage-form-validate.service';
import { NotificacionService } from '@utils/notificacion';
import { CookieUtil } from '@utils/cookies';
import { Globals } from '@config/global';
import { TripettoUtil } from '@utils/tripetto';
import { UtilsMethods_Services } from '@utils/utils';
import { LocalStorageUtil } from '@utils/localstorage';
import { GetUserMeService } from '@apiShared/user/get/get-user-me.service';

@NgModule({
  providers: [RedirectStep],
})
export class RedirectStep {
  private renderer: Renderer2;

  status_process = JsonProcessStatus;
  isviewApp?: boolean;
  cookie_process_uuid = 'cookie-process-uuid';
  cookie_token_id = 'cookie-token-id';
  cookie_phone = 'cookie-phone';
  personal_data_updated = 'personal_data_updated';
  financial_data_updated = 'financial_data_updated';
  response_data_personal = 'response_data_personal';
  updating_personal_data = 'updating_personal_data';
  response_data_financial = 'response_data_financial';
  updating_financial_data = 'updating_financial_data';

  constructor(
    public lowerCasePipe: LowerCasePipe,
    public cookies_util: CookieUtil,
    public globals_var: Globals,
    public tripetto_util: TripettoUtil,
    public utils: UtilsMethods_Services,
    private router: Router,
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    private notificacion_components: NotificacionService,
    private get_user_me: GetUserMeService,
    private stage_form_validate_services: StageFormValidateService,
    public localstorage_util: LocalStorageUtil,
    public sessionStorage_util: LocalStorageUtil,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    if (this.cookies_util.check('view')) {
      this.isviewApp = true;
    } else {
      this.isViewApp();
    }
  }

  /**
   * Maneja la lógica de redirección basada en el banco del usuario y el estado del proceso.
   * @param obj - El objeto que contiene información del banco y del proceso.
   * @returns Una promesa que se resuelve cuando la redirección se completa.
   */
  _RedirectStep(obj: any): Promise<void> {

    return new Promise<void>((resolve, reject) => {
      let bodyStageValidate = {
        data: {
          process_uuid: this.cookies_util.get('id_process'),
        },
        idToken: this.cookies_util.get('id_token_user'),
      };

      if(obj.extra && obj.extra?.is_turbo_cdt){
        this.cookies_util.save("flow_turbo_cdt", "true", 1)
      }

      if (obj.required_identity_validation) {
        this.RouterHTTP_FormularioNuevo({
          routerPath: '/validar-identidad',
          campaign: '',
        });
        resolve();
        return;
      }

      this.handleBrandSwitching(obj);

      if (obj.process_status === 'LEAD-SIN-FORMULARIO') {
        this.stage_form_validate_services
          .STAGE_FROM_VALIDATE(bodyStageValidate)
          .then((proccess) => this.handleLeadSinFormulario(obj, proccess))
          .then(resolve)
          .catch(reject);
      } else {
        const url = this.status_process['raw'][obj.process_status]['url'];
        this.RouterHTTP_FormularioNuevo({
          routerPath: url,
          campaign: this.getCampaign(obj),
        });
        resolve();
      }
    });
  }

  /**
   * Gestiona el cambio de marca según el banco del usuario.
   * @param obj - El objeto que contiene información del banco.
   */
  handleBrandSwitching(obj: any) {
    const FLAMINGO_VALUE = String(this.globals_var.FLAMINGO).toLowerCase();
    const ENTITY_NAME = String(obj.name_bank).toLowerCase();
    const BRAND_TEMPLATE = 'brand_template';
    const BRAND_FLAMINGO = 'brand_flamingo';
    const BRAND_MEJORCDT = 'brand_mcdt';
    let project = document.getElementById('project-mcdt');

    if (ENTITY_NAME !== FLAMINGO_VALUE) {
      const CHECK_BRAND_LS = this.localstorage_util.check(BRAND_TEMPLATE);
      const BRAND_VALUE_LS = this.localstorage_util.get(BRAND_TEMPLATE);
      if (CHECK_BRAND_LS && BRAND_VALUE_LS === FLAMINGO_VALUE) {
        this.localstorage_util.delete(BRAND_TEMPLATE);
        this.renderer.removeClass(project, BRAND_FLAMINGO);
        this.renderer.addClass(project, BRAND_MEJORCDT);
      }
    } else {
      this.localstorage_util.save(BRAND_TEMPLATE, FLAMINGO_VALUE);
      this.renderer.removeClass(project, BRAND_MEJORCDT);
      this.renderer.addClass(project, BRAND_FLAMINGO);
    }
  }

  /**
   * Maneja el proceso para los usuarios que no han completado el formulario.
   * @param obj - El objeto que contiene información del banco y del proceso.
   * @param proccess - El objeto que contiene información del proceso validado.
   * @returns Una promesa que se resuelve cuando se completa el manejo del proceso.
   */
  handleLeadSinFormulario(obj: any, proccess: any): Promise<void> {
    return new Promise<void>((resolve) => {
      this.get_user_me
        .GET_USER_ME(this.cookies_util.get('id_token_user'))
        .then((user) => {
          if (user.code === 'OK') {
            this.processLeadSinFormulario(obj, proccess, user);
          }
          resolve();
        });
    });
  }

  /**
   * Procesa el lead para usuarios que no han completado el formulario.
   * @param obj - El objeto que contiene información del banco y del proceso.
   * @param proccess - El objeto que contiene información del proceso validado.
   * @param user - El objeto que contiene información del usuario.
   */
  processLeadSinFormulario(obj: any, proccess: any, user: any) {
    const advisorr_cdt_regalo = 'cdt-regalo@mejorcdt.com';
    let body = proccess.body;
    let assigned_advisor = user.assigned_advisor;
    //let v2_path = assigned_advisor === advisorr_cdt_regalo ? '/v2' : '';
    let v2_path = '/v2';

    this.notificacion_components.sendNotificacion({
      api: 'send_information_stage_form_validate',
      data: body,
    });

    this.redirectBasedOnPath(obj, body, v2_path, true, proccess, user);

    /* if (assigned_advisor === advisorr_cdt_regalo) {
      this.redirectBasedOnPath(obj, body, v2_path, true, proccess, user);
    } else {
      this.redirectBasedOnPath(obj, body, v2_path, false, proccess, user);
    } */
  }

  /**
   * Redirige al usuario según la ruta proporcionada y otros parámetros.
   * @param obj - El objeto que contiene información del banco y del proceso.
   * @param body - El objeto que contiene información del cuerpo de la solicitud.
   * @param v2_path - La ruta para la versión 2 del formulario.
   * @param isCDTRegalo - Indica si el usuario tiene como asesor asignado a cdt-regalo
   */
  redirectBasedOnPath(
    obj: any,
    body: any,
    v2_path: string,
    isCDTRegalo = true,
    stageObj:any,
    userObj:any
  ) {

    if (obj.id_bank === this.globals_var.ID_FLAMINGO) {
      /*  
      PARA TENER EN CUENTA:
      routerPath debe ser el default del valor del else en la condicion de isCDTRegalo
      Por el momento, todo aquel usuario que sea de cdt-regalo@mejorcdt.com forzará
      la vista de datos-personales obligatoriamente, con esto se garantiza
      probar los 3 formularios
      */
      if (body.path !== '/datos-personales' && v2_path) {
        this.RouterHTTP_FormularioNuevo({
          routerPath:  isCDTRegalo ? `${v2_path}/datos-personales` : '/confirma-tus-datos',
          campaign: this.globals_var.FLAMINGO,
          stage_path: body.path,
          isCDTRegalo: isCDTRegalo
        });
      } else {
        this.RouterHTTP_FormularioNuevo({
          routerPath: isCDTRegalo ? `${v2_path}/datos-personales` : `${v2_path}${body.path}`,
          campaign: this.globals_var.FLAMINGO,
          isCDTRegalo: isCDTRegalo
        });
      }
    } else {
      let campaign = this.globals_var.CAMPAIGN_MEJORCDT;
      const obj_stage = stageObj.body;
      const obj_extra_process = obj.extra;
      let router_path = `${v2_path}${body.path}`; 
    
      // Si existe 'is_turbo_cdt' y es verdadero, se actualiza 'router_path'
      if (obj_extra_process?.is_turbo_cdt && obj_stage.path === "/datos-personales") {
        router_path = `${v2_path}/preguntas-cdturbo`;
      }
    
      this.RouterHTTP_FormularioNuevo({
        routerPath: router_path,
        campaign: campaign,
        stage_path: body.path,
        isCDTRegalo: isCDTRegalo
      });
    }
  }

  /**
   * Obtiene la campaña basada en el objeto del banco.
   * @param obj - El objeto que contiene información del banco.
   * @returns El nombre de la campaña.
   */
  getCampaign(obj: any): string {
    const FLAMINGO_VALUE = String(this.globals_var.FLAMINGO).toLowerCase();
    const ENTITY_NAME = String(obj.name_bank).toLowerCase();
    return ENTITY_NAME === FLAMINGO_VALUE ? FLAMINGO_VALUE : 'mcdt';
  }

  /**
   * Navega a la ruta especificada con los parámetros proporcionados para el formulario nuevo (nativo).
   * @param params - El objeto que contiene la ruta y los parámetros de la campaña.
   */
  RouterHTTP_FormularioNuevo(params: any) {
    this.navigateWithParams(
      params.routerPath,
      params.campaign,
      params.stage_path,
      params.isCDTRegalo
    );
  }

  /**
   * Navega a la ruta especificada con los parámetros proporcionados para el flujo normal.
   * @param routerPath - La ruta de la redirección.
   * @param campaign - El nombre de la campaña.
   */
  RouterHTTP_FlujoNormal(routerPath: string, campaign: string) {
    this.navigateWithParams(routerPath, campaign);
  }

  /**
   * Navega a la ruta especificada con los parámetros proporcionados.
   * @param routerPath - La ruta de la redirección.
   * @param campaign - El nombre de la campaña.
   * @param stage_path - La ruta de la etapa, si aplica.
   */
  navigateWithParams(
    routerPath: string,
    campaign: string,
    stage_path?: string,
    isCDTRegalo?: boolean
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const queryParams: any = {};
      const FLAMINGO_VALUE = String(this.globals_var.FLAMINGO).toLowerCase();
      const forceForm = this.utils.getQueryParam('force') === 'OK';
      const preloads = this.utils.getQueryParam('preloads') === 'false';
      const locationPath = window.location.pathname;

      if (campaign.toLowerCase() === FLAMINGO_VALUE) {
        queryParams.campaign = FLAMINGO_VALUE;
      }

      if(isCDTRegalo){
        queryParams.force = 'OK';
      }

      if (stage_path) {
        if (stage_path === '/datos-personales') {
          queryParams.force = 'OK';
        } else if (stage_path === '/datos-financieros') {
          queryParams.stage = 2;
        } else if (stage_path.startsWith('/preguntas')) {
          queryParams.stage = 3;
        }
      }

      if (forceForm) {
        queryParams.force = 'OK';
        const pathValid = this.getPathValidList();
        if (pathValid.includes(locationPath)) {
          routerPath = locationPath;
        }
      }

      if(preloads){
        queryParams.preloads = 'false';
      }

      if (this.isviewApp) {
        queryParams.view = 'app';
      }

      this.router.navigate([routerPath], { queryParams });
      setTimeout(() => {
        this.notificacion_components.sendNotificacion({
          api: 'send_information_finish_redirect',
        });
      }, 500);
    }
  }

  /**
   * Obtiene una lista de rutas válidas para la redirección.
   * @returns Una lista de rutas válidas.
   */
  getPathValidList(): string[] {
    return [
      '/confirma-tus-datos',
      '/datos-financieros',
      '/datos-personales',
      '/preguntas-ban100',
      '/preguntas-bancamia',
      '/preguntas-bancomundomujer',
      '/preguntas-bancomundomujer',
      '/preguntas-bancow',
      '/preguntas-coltefinanciera',
      '/preguntas-coltefinanciera/flamingo',
      '/preguntas-credifamilia',
      '/preguntas-financieradannregional',
      '/preguntas-mibanco',
      '/v2/datos-financieros',
      '/v2/datos-personales',
      '/v2/preguntas-ban100',
      '/v2/preguntas-bancamia',
      '/v2/preguntas-bancomundomujer',
      '/v2/preguntas-bancow',
      '/v2/preguntas-coltefinanciera',
      '/v2/preguntas/coltefinanciera/flamingo',
      '/v2/preguntas-credifamilia',
      '/v2/preguntas-financieradannregional',
      '/v2/preguntas-mibanco',
    ];
  }

  isViewApp() {
    if (isPlatformBrowser(this.platformId)) {
      //Validamos si la vista la abren desde el aplicativo
      const url = new URL(window.location.href);
      const viewParam = url.searchParams.get('view');
      // Verificar si viewParam es igual a 'app' (asegurando que viewParam no sea nulo)
      if (this.cookies_util.check('view')) {
        this.isviewApp = true;
      } else if (viewParam !== null && viewParam === 'app') {
        this.isviewApp = true;
        this.cookies_util.save('view', 'app', 1);
      }
    }
  }

  createFinancialData(body: any) {
    const data = JSON.stringify({
      [this.cookie_process_uuid]: this.cookies_util.get('id_process'),
      [this.cookie_token_id]: this.cookies_util.get('id_token_user'),
      [this.cookie_phone]: this.cookies_util.get('phone-full'),
      'incomes.monthly_incomes': String(body.financial_data.monthly_incomes),
      total_assets: String(body.financial_data.total_assets),
      total_liabilities: String(body.financial_data.total_liabilities),
      is_income_statement:
        body.financial_data.is_income_statement === 'true' ? 'True' : 'False',
    });

    this.localstorage_util.save(this.response_data_financial, data);
    return data;
  }

  createPersonalData(body: any) {
    const data = JSON.stringify({
      [this.cookie_process_uuid]: this.cookies_util.get('id_process'),
      [this.cookie_token_id]: this.cookies_util.get('id_token_user'),
      [this.cookie_phone]: this.cookies_util.get('phone-full'),
      ...body.personal_data,
    });

    this.localstorage_util.save(this.response_data_personal, data);
    return data;
  }

  redirectTo(
    path: string,
    responseDataKey: string,
    data: any,
    updatingDataKey: string
  ) {
    this.RouterHTTP_FlujoNormal(path, '');
    this.sessionStorage_util.save(updatingDataKey, 'true');
  }

  removeQueryParam(param: string) {
    // Obtén los parámetros de consulta actuales
    const queryParams = { ...this.route.snapshot.queryParams };

    // Elimina el parámetro deseado
    delete queryParams[param];

    // Navega a la URL actualizando los parámetros de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // Preserva los parámetros restantes
    });
  }
}
