import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class Globals {
    /* Nombre de los bancos aliados */
    public BANCAMIA            =              "Bancamia";
    public BAN100              =              "Ban100";
    public BANCOW              =              "Banco W";
    public COLTEFINANCIERA     =              "Coltefinanciera";
    public FLAMINGO            =              "Flamingo";
    public MIBANCO             =              "MiBanco";
    public MUNDOMUJER          =              "Mundo Mujer";
    public BANCO_MUNDO_MUJER   =              "Banco Mundo Mujer";
    public DANNREGIONAL        =              "Dann Regional";
    public FINANCIERA_DANN_REGIONAL =         "Financiera Dann Regional";
    public IBRBAN100           =              "IBR Ban100";
    public IPCBANCOW           =              "IPC Banco W";
    public CREDIFAMILIA        =              "Credifamilia";
    /* ID de los bancos aliados */
    public ID_BANCAMIA         =              11;
    public ID_BAN100           =              8;
    public ID_BANCOW           =              15;
    public ID_COLTEFINANCIERA  =              24;
    public ID_FLAMINGO         =              27;
    public ID_MIBANCO          =              10;
    public ID_MUNDOMUJER       =              13;
    public ID_DANNREGIONAL     =              28;
    public ID_IBRBAN100        =              8;
    public ID_IPCBANCOW        =              15;
    public ID_CREDIFAMILIA     =              29;
    /* Temas para Transloco */
    public CAMPAIGN_FLAMINGO  =              'Flamingo';
    public CAMPAIGN_MEJORCDT  =              'MejorCDT';
    /* Nombre de Bancos no aliados */
    public BOGOTA              =              "Bogota";
    public DAVIVIENDA          =              "Davivienda";
    /* simulate Investment */
    public EXCLUDE_ONYL_SIMULATE =            [25, 26, 27, 9, 21, 2];
    public TERM_GLOBAL         =              360;
    public POSITION_NAV_MOBILE =              0;
    public LIST_TERMS_GLOBAL   =              [90, 180, 270, 360, 540]
    public AMOUNT_GLOBAL       =              10000000

    public PATHS_FORM_MINUTES  =              ['/a-unos-minutos-de-abrir-tu-cdt', '/mejores-bancos-para-cdt-hoy', '/beneficios-ban100', '/turbo'];
    public PATHS_CONFIRM_PROCESS_EXIT = [
      '/preguntas-ban100',
      '/preguntas-bancamia',
      '/preguntas-bancow',
      '/preguntas-bancomundomujer',
      '/preguntas-mibanco',
      '/preguntas-coltefinanciera',
      '/preguntas-financieradannregional',
      '/datos-personales',
      '/datos-financieros',
      "/confirma-tus-datos",
      '/preguntas-credifamilia',
      '/v2/preguntas/coltefinanciera/flamingo?campaign=Flamingo',
      '/v2/preguntas-ban100',
      '/v2/preguntas-bancamia',
      '/v2/preguntas-bancow',
      '/v2/preguntas-bancomundomujer',
      '/v2/preguntas-mibanco',
      '/v2/preguntas-credifamilia',
      '/v2/preguntas-coltefinanciera',
      '/v2/preguntas-financieradannregional',
      '/v2/preguntas-credifamilia',
      '/v2/datos-personales',
      '/v2/datos-financieros',
    ];


}
