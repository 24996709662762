export const environment = {
    baseUrl:                'http://mcdt-portal-usuario-test.s3-website-us-east-1.amazonaws.com/',
    production:              false,
    API_KEY:                'Tr-x=woth4Ma*8-lg4f#',
    API_BASE_URL:           'https://api-tes.mejorcdt.com/',
    version:                'v1/',
    API_NEW_PDF:            'https://6gcpzr6ne1.execute-api.us-east-1.amazonaws.com/test/new-pdf',
    API_BANK_RATES:         'https://6gcpzr6ne1.execute-api.us-east-1.amazonaws.com/test/bank-rates',
    API_ADELAIDA:           'https://723zjxdku1.execute-api.us-east-1.amazonaws.com/tes/',
    S3_STATIC_FILES:        'https://mcdt-static-tes.s3.amazonaws.com',
    GTA_TRACKING_CODE       :'GTM-MZFSNS2',
    IMAGE_DYNAMIC_FIGURES   :'https://mcdt-static-tes.s3.amazonaws.com/images/web/las-mejores-tasas-en-mejorcdt-actualizadas.png'
};
  