
import { Component, OnInit, PLATFORM_ID, Inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateUser } from 'src/app/class/user/create/create-user';
import { RedirectProcessStatus} from 'src/app/class/process-user/redirect/redirect-process-status';
import { CloseModal } from 'src/app/class/close-modal/close-modal';
import { NotificacionService } from '@utils/notificacion';
import { AllProcessViewComponent } from '../all-process-view/all-process-view.component';
import { GetUserMe } from '@class/user/me/get-user-me';
import { CookieUtil } from '@utils/cookies';
import { Router } from '@angular/router';
import { environment } from '@enviroment/environment';
import { LoginService } from '@apiShared/login/login.service';
import { getDataCampaign } from '@utils/get-campaign';
import { UtilsMethods_Services } from '@utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class ModalSharedLoginComponent implements OnInit, AfterViewChecked {
  env = environment;
  LoginDataModal:any;
  idToken:any;
  isErrorCreate?:boolean;
  /* Control de enviar, se utilizará para manejo de validaciones */
  submitted = false;
  submitOpt = false;
  /* FormGroups de los formularios de la vista */
  Form_OTP: FormGroup;
  /* Manejo de texto y link de imagen para la vista de Inicio de sesión */
  imgLogin: any;
  titleHeader: any;
  descriptionLogin: any;
  titleButton: any;
  /* Control de que vista mostrar y que no */
  controlFormSiginUp = false;
  controlFormSendCode = false;
  controlRegisterUser = false;
  /* Necesario para la validaciones del codigo otp */
  otp?: string;
  /* Reenvio de código */
  timeLeft: number = 60;
  interval: any;
  resendClassEnabled:any = 'resend-blocked';
  resendStatusCode = false;
  /* Esta variable contendrá el status de ApiSignIn() */
  statusSigIN: any;
  attemps_otp = 0;
  /* Bloquearemos el modal mientras los servicios nos responden */
  screenBloqued = false;
  /* Tendra conexión con screeBloqued, ya que bajara la opacidad del body modal */
  opacityLoadOTP = 1;
  /* Esta variable solo funcionara cuando la vista sea de process_id */
  allowSendOtp = false;
  /*  */
  singup_username: any;
  signup_session:any;
  show_view: any;

  step_notificacion_components:any;
  id_process_conn_between_component: any;
  info_obj_to_create_process: any;
  disable_modal_step_notificacion_components: any;
  open_from_step_notificacion_components:any;
  resp_conn_between_component:any = {
    data: {
      hide_alert: ''
    }
  };

  infoUser : any = {};
  messageAlert: any = {
    message : '',
    error: 0
  };

  getCampaign:any = {};
  inputPhoneInternational_Emit: any = {};
  InputOTP_Emit: any = {};

  constructor(
    public fb: FormBuilder,
    public cookies : CookieUtil,
    public apiLogin: LoginService,
    public activeModal: NgbActiveModal,
    public _getDataCampaign: getDataCampaign,
    public utils:UtilsMethods_Services,
    private router: Router,
    private _closeModal: CloseModal,
    private CreateUser: CreateUser,
    private get_redirect_process_user: RedirectProcessStatus,
    private getUserMe_helper: GetUserMe,
    private modalService: NgbModal,
    private notificacion_components: NotificacionService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._getDataCampaign.getCampaignDataUpdates().subscribe(data => {
      this.getCampaign = data;
    });
    this.notificationAlerts();
    /* Formgroup del formulario Login, sobre el número celular - Opcional */
    this.Form_OTP = this.fb.group({
      phone_international: new FormControl(this.cookies.get('phone-number'), [Validators.required]),

      fullname: new FormControl(this.cookies.get('fullname'), [
        Validators.required,
        Validators.minLength(3),
      ]),
    });
  }

  EmiteinputPhoneInternational(event: any){
    this.inputPhoneInternational_Emit = event;
  }

  EmiteinputOTP(event: any){
    this.InputOTP_Emit = event;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.operatorView(this.LoginDataModal);
  }

  notificationAlerts(){
    this.notificacion_components.receiveNotificacion.subscribe((data: any) => {

      if (
        data.api === 'send_information_error_401_unauthorized' ||
        data.api === 'send_information_error_500_expiration_cookies' ||
        data.api === 'send_information_error_404_not_found_process'
      ) {
        this.screenBloqued = false;
        this.opacityLoadOTP = 1;
      }

      switch (data.api) {
        case 'send_information_error_401_unauthorized':
          this.messageAlert = {
            message: 'Se ha producido un error. <br> Por favor, ponte en contacto con tu asesor indicando el siguiente código <b>00-401</b>',
            error: 401
          };
          break;

        case 'send_information_error_404_not_found_process':
          this.resp_conn_between_component.data.hide_alert = false;
          this.getInfoUser();
          this.messageAlert = {
            message: `
              <b> Error: 404 </b> <br/>
              <span class='mb-0 mt-2'> ${this.infoUser.full_name}, no hemos encontrado tu proceso. ¿Estás seguro de que estás aperturando tu CDT con esté número de celular <b>${this.infoUser.phone_number}</b>? Si no es así, por favor edita tu número de celular</span>
            `,
            error: 404
          }
          break;

        case 'send_information_error_500_expiration_cookies':
          this.messageAlert = {
            message: 'Por seguridad, tu sesión ha expirado. Necesitamos que valides nuevamente tu identidad',
            error: 500
          };
          break;
      }


    });
  }

  operatorView(data:any){
    this.resp_conn_between_component = data;
    this.disable_modal_step_notificacion_components = data.data.disable_modal_close_button;
    /* Añadimos el paso actual en que se activo la vista */
    this.step_notificacion_components = data.data.step;
    /* Queremos comprobar si desde la comunicación existe el atributo de id_process en el objecto */
    this.id_process_conn_between_component = Boolean(data.data.id_process);
    /* Cerramos modal */
    this._closeModal._CloseModal(data.modal);
    /* Si el paso es de la vista de hoy-los-bancos-ofrecen, añadiremos a info_obj_to.. el objeto que llegue de la comunicación */
    if(this.step_notificacion_components == 2 || this.step_notificacion_components == 7){
      this.info_obj_to_create_process = data.obj_info_obj_create_process;
    }
    /* La propiedad 'open_from_step' existe en el objeto data */
    /* Si existe, entonces quiere decir el componente login ha sido abierto desde un paso del tubo */
    if ('open_from_step' in data.data) {
      /* open_from_step comunicara a diferentes componentes su resultado, con el fin de activar funciones en otros componentes  */
      this.open_from_step_notificacion_components = data.data.open_from_step
    }
    /* Si no existe la cookie de id_token_user significa que vamos a necesitar abrir el modal */
    if(this.cookies.check('id_token_user') !== true){
      /* Manejo de código para visualización de errores en el formulario del modal */
      this.statusSigIN = 200;
      if(this.cookies.check('phone-full') == true){
        this.apiSignUp(
          {
            phone: this.cookies.get('phone-full')
          }
        );
        /* Cambiamos textos en la vista para digitalizar el otp */
        this.updatedSiginElement();
      } else {
         /* Si no existe cookie del celular, actualizaremos la vita para que el usuario pueda enviar el otp */
        this.updatedSigUpElement();
      }
    } else if(this.id_process_conn_between_component == false){
      //Si existe el atributo en el objecto de activate_login_component, quiere decir que no existe la cookie de id_process
      /* Eliminamos cookies del Login que ya no tienen funcion  */
      this.deleteLoginCookies();
      /* Llamamos abrir el componente para listar todos los procesos del usuario */
      this.openAllProcesessModal(this.cookies.get('id_token_user'));
    }
  }

  /* Esta función tendrá el control total de todo el formulario de inicio de sesión */
  loginForm(){
    /* Nos ayudara a validar los diferentes formularios */
    this.submitted = true;
    this.submitOpt = true;
    /* Valid */
    let phone_valid;
    let phone_value_full = "";
    let phone_value = "";

    if(this.controlFormSiginUp){
      phone_valid = this.inputPhoneInternational_Emit.validate;
      phone_value = this.inputPhoneInternational_Emit.phone_number;
      phone_value_full = this.utils.deleteSpace(this.inputPhoneInternational_Emit.phone_full);
    }

    if(this.controlRegisterUser && this.Form_OTP.controls['fullname'].errors === null){
      this.registerUser();
    }

    if(phone_valid && this.allowSendOtp){
      /* Guardamos el nuevo número a la Cookie */
      this.cookies.save('phone-full', phone_value_full, 1);
      /* Guardamos el número original sin el prejifo */
      this.cookies.save('phone-number', phone_value, 1);
      /* Ejecutamos apiSignUp para el envio del mensaje */
      this.apiSignUp(
        {
          phone: phone_value_full
        }
      );
      /* Para que nuevamente sirva el boton del formulario, debemos poner en false allosSendOtp */
      this.allowSendOtp = false;
    }
    if(this.InputOTP_Emit.validate && this.controlFormSendCode){
      /* Mandamos a ejecutar la funcion ApiSignIN */
      this.apiSignIn();
    }
  }

  apiSignUp(params: any): void{
    let qp_campaign = this.utils.getQueryParam('campaign')?.toLowerCase();
    let data:any = {
      phone_number: params.phone,
      device_key: 'pc'
    }

    if(qp_campaign === 'flamingo'){
      data.campaign = 'flamingo'
    }

    /* Mientas carga, vamos a bloquear el body modal */
    this.screenBloqued = true;
    /* Bajaremos la opacidad del body modal */
    this.opacityLoadOTP = 0.5;
    /* Consultamos el servicio de signUP */
    this.apiLogin.SIGN_UP(data).then(data => {
      /* Si todo es exitoso, llamamos updatedSiginElement para que nos cambie las textos e imagenes para sigin */
      this.updatedSiginElement();
      /* Volvemos a la normaldidad del body modal*/
      this.screenBloqued = false;
      this.opacityLoadOTP = 1;
      /* Guardamos en el token cada una de los atributos que necesitaremos para implemantar los otros servicios */
      this.singup_username = data.ChallengeParameters.USERNAME;
      this.signup_session = data.Session;
      this.statusSigIN = 200;
    }, error => console.log('Ha sucedido un error', error));

  }

  apiSignIn(): void{
    const data: any = {
      'username': this.singup_username,
      'answer': this.InputOTP_Emit.otp,
      'session': this.signup_session
    }
    /* Mientas carga, vamos a bloquear el body modal */
    this.screenBloqued = true;
    /* Bajaremos la opacidad del body modal */
    this.opacityLoadOTP = 0.5;
    /* Consultamos el servicio de SignIN */
    this.apiLogin.SIGN_IN(data).then(data => {
      /* Enviamos notificacion a la clase para que otros componentes detecten */
      this.notificacion_components.sendNotificacion({
        api: "successfully_logged_in",
        response: data
      });
      if (data.code === "LOGON_SUCCEEDED") {
        this.idToken = data.AuthenticationResult.IdToken;
        const step = this.step_notificacion_components;
        /* Actualizamos el estado de código */
        this.statusSigIN = data.ResponseMetadata.HTTPStatusCode;

        this.getUserMe_helper.UserMe_Helper(this.idToken).then(userData => {
          /* Si el usuario esta un paso diferente a 0, no le cargaremos su token */
          if (userData === 200) {
            this.cookies.save('id_token_user', this.idToken, 1);
            if (step === 2 || step === 7 || step === 3) {
              /* Eliminamos cookies del Login que ya no tienen función */
              this.deleteLoginCookies();
              /* Llamamos abrir el componente para listar todos los procesos del usuario */
              setTimeout(() => {
                this.openAllProcesessModal(this.idToken);
              }, 1000);
            } else if(step === 1){
              /* Eliminamos cookies del Login que ya no tienen función */
              this.deleteLoginCookies();
              /* Aquí es por medio del flujo para continuar un proceso (link: /?process_id) */
              /* Consumimos el servicio helper que nos redireccionará al paso según el backend */
              this.get_redirect_process_user.RedirectProcessUser_Helper({
                "idProcess": this.resp_conn_between_component.data.id_process,
                "idToken": this.idToken,
                "modal": "btn-close-login"
              });
            } else if(step === 0){
              this._closeModal._CloseModal('btn-close-login');
              this.router.navigate(['/portal/dashboard']);
            }
          } else if (userData === 404) {
            if (step === 2 || step === 7) {
              let bodyUser = {
                data: {
                  full_name: this.cookies.get('fullname')
                },
                idToken: this.idToken
              }
              this.CreateUser.CreateUser_Helper(bodyUser).then(status => {
                if(status === 200){
                  this.screenBloqued = false;
                  this.opacityLoadOTP = 1;
                  /* Eliminamos cookies del Login que ya no tienen función */
                  this.deleteLoginCookies();
                  this.openAllProcesessModal(this.idToken);
                } else {
                  this.isErrorCreate = true;
                }
              });
            } else {
              this.updateRegisterUserElement();
            }
          }
        });
      } else {
        /* En caso de que el usuario digite un código de opt incorrecto, aumentara los intentos */
        this.attemps_otp++;
        /* Si los intentos es igual a 2, se mostrará un mensaje de error indicando que debe reenviar nuevamente el código ootp */
        if(this.attemps_otp == 2){
          this.statusSigIN = 201;
        }
        /* Si los intentos es igual a 1, se mostrará un mensaje indicando que tiene 1 intento más */
        if(this.attemps_otp == 1) {
          this.signup_session = data.error.Session;
          this.statusSigIN = 401;
        }
        /* Si existe un error, volveremos a la normalidad del body modal */
        this.screenBloqued = false;
        /* Bajaremos la opacidad del body modal */
        this.opacityLoadOTP = 1;
      }
    }, error => console.log('Ha sucedido un error', error));
  }

  getInfoUser(){
    if(
      this.cookies.check("fullname") &&
      this.cookies.check("phone-full") &&
      this.cookies.check("id_token_user")
    ){
      this.infoUser.full_name = this.cookies.get("fullname");
      this.infoUser.phone_number = this.cookies.get("phone-full");
    }
  }

  returnSignUp(){
    this.cookies.delete("id_token_user");
    this.cookies.delete("phone-full");
    this.cookies.delete("phone-number");
    this.cookies.delete("fullname");
    this.Form_OTP.get('phone_international')?.setValue('');
    this.messageAlert.message = '';
    this.updatedSigUpElement();
  }

  registerUser(){
    this.screenBloqued = true;
    this.opacityLoadOTP = 0.5;
    let bodyUser = {
      data: {
        full_name: this.Form_OTP.controls['fullname'].value
      },
      idToken: this.idToken
    }
    this.CreateUser.CreateUser_Helper(bodyUser).then(status => {
      this.screenBloqued = false;
      this.opacityLoadOTP = 1;
      if(status === 200){
        this._closeModal._CloseModal('btn-close-login');
        this.router.navigate(['/portal/dashboard']);
      } else {
        this.isErrorCreate = true
      }
    });
  }

  updateRegisterUserElement(){
    /* Si existe, entonces mostraremos la vista de SignUP */
    this.screenBloqued = false;
    this.opacityLoadOTP = 1;
    this.controlFormSiginUp = false;
    this.controlFormSendCode = false;
    this.controlRegisterUser = true;
    /* Establecemos el valor por defecto de los textos */
    this.imgLogin = `${this.env.S3_STATIC_FILES}/images/web/register-user.png`
    this.titleHeader = 'Registrate';
    this.titleButton = 'Registrarse';
  }

  /* Actualizamos los textos y imagenes de la vista signIN */
  updatedSigUpElement(){
    if (isPlatformBrowser(this.platformId)) {
      /* Si existe, entonces mostraremos la vista de SignUP */
      this.controlFormSiginUp = true;
      this.controlFormSendCode = false;
      this.allowSendOtp = true;
      /* Establecemos el valor por defecto de los textos */
      this.imgLogin = 'assets/images/web/user-login.png'
      this.titleHeader = '¡Hola!';
      this.titleButton = 'Enviar Código';
    }
  }

  /* Actualizamos los textos y imagenes de la vista signIN */
  updatedSiginElement(){
    /* Ocultamos SignUP y mostramos SignIN */
    this.controlFormSiginUp = false;
    this.controlFormSendCode = true;
    /* Establecemos el valor por defecto de los textos */
    this.imgLogin = `${this.env.S3_STATIC_FILES}/images/web/user-code.png`
    this.titleHeader = 'Ingresa el código';
    this.descriptionLogin = "Ingresa el código de verificación que llegó a tu celular por SMS (mensaje de texto) y WhatsApp";
    this.titleButton = 'Verificar código';
    /* Guardamos el valor por defecto de TimerCode, esto con el fin de ir aumentando el tiempo cada vez que el usuario reenvie el código */
    this.cookies.save('timeCode', String(this.timeLeft), 1);
    /* Cerramos el intervalo del contador */
    clearInterval(this.interval)
    /* Empezamos a utilizar el contador para reenviar código */
    this.resendCodeTime();
  }

  /* Llamamos el modal de visualziar todos los procesos del usuario */
  openAllProcesessModal(id_token:any){
    if (Boolean(this.info_obj_to_create_process)) {
      let newProcessData = this.info_obj_to_create_process;
      this._closeModal._CloseModal('btn-close-login');
      this.router.navigate(['/mis-procesos'], { state: { newProcessData } });
    } else {
      let data:any = {
        "step": this.step_notificacion_components,
        "modal": "btn-close-login",
        "id_token": id_token
      }
      /* Si info_obj_to_create_process tiene algún valor, añadiremos al obj de data nueva propiedad de data que contendrá la información del cdt para crear procesos (Valido para paso 2 y 7) */
      Boolean(this.info_obj_to_create_process) == true ? data.data = this.info_obj_to_create_process : '';
      /* Si open_from_step_notificacion_components tiene algún valor, añadiremos al obj de data nueva propiedad */
      Boolean(this.open_from_step_notificacion_components) ? data.open_from_step = this.open_from_step_notificacion_components : '';
      /* Llamamos modal */
      const modalRef = this.modalService.open(
        AllProcessViewComponent,
        {
          centered: true,
          size: 'lg width-all-process',
          backdrop:'static'
        }
      );
      modalRef.componentInstance.AllProcessDataModal = data;
    }
  }

  /* Contador para volver a reenviar código */
  resendCodeTime(){
    /* Iniciamos un Intervalo de segundos */
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        /* Si el temporizador es mayor a cero, quiere decir que esta en ejecución, por tanto reenviar código se inhabilitara */
        this.resendClassEnabled = 'resend-bloqued';
        /* Convertirmos a false la variable, que tiene como función ser un parametro de la función resendCode */
        this.resendStatusCode = false;
        /* Contador para atras xD */
        this.timeLeft--;
      } else {
        if(this.timeLeft == 0)  {
          /* Si el contador llega a cero, hago un pause en el intervalo y parara */
          clearInterval(this.interval)
          /* Convertimos a true la variable, quiere decir que  vamos a poder reenviar código*/
          this.resendStatusCode = true;
          /* Habilitamos reenviar código en el front */
          this.resendClassEnabled = 'resend-enabled';
        }
      }
    },1000)
  }
  /* Reenvio de codigo */
  resendCode(params:any){
    if(params.status == true){
      /* Reenviamos código otp */
      this.resendCodeApi(params);
      /* Vovlermos intentos de codigo a cero */
      this.attemps_otp = 0;
      /* ELiminamos el intervalo del contador */
      clearInterval(this.interval);
      /* Si el parametro status, osea la variable this.resendStatusCode es true, entonces envie código, aúmente el contador y convierta nuevamente this.resendStatusCode en false */
      this.timeLeft = Number(this.cookies.get('timeCode')) + 30;
      /* Damos nuevo valor a la cookie timeCode con el nuevo tiempo */
      this.cookies.save('timeCode', String(this.timeLeft), 1);
      /* Restablecemos a false el parametros */
      this.resendStatusCode = false;
      /* Bloqueamos nuevamente reenviar-codigo */
      this.resendClassEnabled = 'resend-bloqued';
      /* Una vez todo listo, llamaremos al contador */
      this.resendCodeTime();
    } else {

    }
  }

  /* Reenviamos el código opt, (Esto será temporal) */
  resendCodeApi(params: any){
    let data:any = {
      phone: params.phone,
      device_key: 'pc'
    }

    if(params.call_code){
      data.call_user = "true"
    }


    this.apiSignUp(data);
  }

  deleteLoginCookies(){
    this.cookies.delete('process_id');
    this.cookies.delete('timeCode');
  }

  /* Esta función es importante, ya que a través de ella vamos a utilizar la clase FormControl de cada input/campo, para poder realizar las validaciones en las vista*/
  get f(): { [key: string]: AbstractControl } {
    return this.Form_OTP.controls;
  }

}
